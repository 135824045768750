.swal-modal {
  /* background-color: rgba(63,255,106,0.69); */
  border: 3px solid white;
}
.swal-text {
  /* background-color: #FEFAE3; */
  padding: 17px;
  /* border: 1px solid #F0E1A1; */
  display: block;
  margin: 22px;
  text-align: center;
  color: #61534e;
  font-family: neue-bold !important;
}
