.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.bheader {
  padding-bottom: 10px;
  color: black;
}


.App-header.btable {
  table-layout: fixed;
  padding-bottom: 5px;
}


.blabel {
  text-transform: lowercase;
  white-space: nowrap;
  text-align: right;
  width: 10%;
  color: grey;
}

.bvalue {
  text-align: left;
  width: 25%;
  color: black;
}

.blong {
  text-overflow: ellipsis;
  text-align: left;
  font-weight: bold;
  color: green;
  width: 200px;
  overflow: clip;
  max-width: 200px;
}
